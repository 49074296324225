import React from 'react';
import { Row } from 'reactstrap'
import roundDark from 'images/shapes/round-dark.png'

const Footer = () => {

	return (
	    <React.Fragment>
		    {/*<FooterTop />*/}
		
		    <section className="section">
			    <div className="container-fluid">
				    <Row>
					    <div className="home-shape-bottom">
						    <img src={roundDark} alt="" className="img-fluid mx-auto d-block" />
					    </div>
				    </Row>
			    </div>
		    </section>
		    
			<footer className="footer">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
							<a className="logo-footer" href="/">Is het een schrikkeljaar<span className="text-primary">?</span></a>
							<p className="mt-4">Een simpele website die je kan vertellen of dit(of een ander) jaar een schrikkeljaar is.</p>
						</div>
					</div>
				</div>
			</footer>
			<hr/>
		
			<footer className="footer footer-bar">
				<div className="container text-center">
					<div className="row align-items-center">
						<div className="col-sm-6">
							<div className="text-sm-left">
								<p className="mb-0">© {new Date().getFullYear()} Is het een schrikkeljaar?</p>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</React.Fragment>
    );
}

export default Footer;
