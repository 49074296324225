// React Basic and Bootstrap
import React from 'react';
import FactComponent from 'components/Fact'

const Fact5 = () => {

    return (
        <FactComponent
            title={ '5 | Sommige wetenschappers willen schrikkeljaar afschaffen' }
            paragraphs={[
                'Eind 2011 stelden sterrenkundige Richard Conn Henry en econoom Steve H. Hanke van de Johns Hopkins-universiteit voor om de gregoriaanse kalender te veranderen. Ze willen de kalender zo aanpassen dat Kerstmis (en Nieuwjaarsdag, je verjaardag, enzovoort) elk jaar op dezelfde dag valt.',
                'Henry en Hanke bouwen voort op een idee van Bob McClenon. Ze stellen voor dat elke maand 30 dagen duurt, behalve maart, juni, september en december, die elk 31 dagen hebben. Schrikkeljaren schaffen ze af. Hierdoor duurt elk normaal jaar een dag korter dan nu en elk voormalig schrikkeljaar twee dagen korter. Deze extra dagen worden gecompenseerd door eens in de vijf of zes jaar een ‘mini-maand’ van één week aan de kalender toe te voegen.',
                'De twee wetenschappers zien veel voordelen in hun systeem. Doordat feestdagen elk jaar op dezelfde dag vallen, wordt het voor bedrijven en scholen makkelijker om een jaarplanning te maken. Daarnaast zorgt de verdeling van het jaar in kwartalen van 91 dagen (twee maanden van 30 dagen en één van 31 dagen) ervoor dat berekeningen van rentes eenvoudiger en eerlijker worden, volgens econoom Hanke.',
                'Inmiddels zijn we al een aantal jaar verder en is de voorgestelde kalender van Henry en Hanke nog steeds niet aangenomen. En dat is maar goed ook, want anders zouden de verjaardagen van mensen die geboren zijn op 31 januari, 31 mei, 31 juli, 31 augustus of 31 oktober van de kalender verdwijnen.',
            ]}
        />
    )
}

export default Fact5;
