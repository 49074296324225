import React, { useState } from 'react';
import Layout from './components/Layout/';
import { BrowserRouter, Link, Route, Router, Routes, useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";
import routes, { routePaths } from './routes';
import { Helmet } from 'react-helmet'
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap'

import './Apps.css';
import './css/materialdesignicons.min.css';

const trackingId = "G-DJMTZRZ6FM"; // Replace with your Google Analytics tracking ID
ReactGA.initialize( trackingId );

const UsePageViews = () => {
	let location = useLocation();
	
	// Update the user's current page
	ReactGA.set( {
		page: location.pathname
	} );
	
	// Record a pageview for the given page
	ReactGA.send({
		page: location.pathname
	});
	
	return <></>
}

const withLayout = ( WrappedComponent ) => {
	return (
		<Layout>
			<WrappedComponent/>
		</Layout>
	);
}

const App = () => {
	const [ collapsed, setCollapsed ] = useState( true )
	
	const toggleNavbar = () => {
		setCollapsed( !collapsed )
	};
	
	const routeElements = routes.map( ( route, idx ) => {
		return (
			<Route
				path={ route.path }
				element={ withLayout( route.component ) }
			/>
		)
	});
	
	const navItems = [
		{ to: '/', name: 'Home' },
		{ to: routePaths.ABOUT, name: 'Wat is een schrikkeljaar' },
		{ to: routePaths.FACTS, name: 'Feitjes' },
	]
	
	return (
		<React.Fragment>
			<BrowserRouter>
				<UsePageViews />
				
				<Helmet
					defaultTitle={ `Is het een schrikkeljaar`}
					titleTemplate={ `%s - Is het een schrikkeljaar`}>
					
					<meta name="description" content={`Altijd al willen weten welke jaren schrikkeljaren zijn? De berekening is heel eenoudig, maar je moet het wel even weten! Wil je de berekening weten? Open de site :)`} />
					<meta name="keywords" content={`schrikkel,jaar,schrikkelaar,29,februari`} />
				</Helmet>
				
				<Navbar light expand={'lg'} color="faded">
					<NavbarBrand href="/" className="mr-auto">
						<h6>Is het een schrikkeljaar</h6>
					</NavbarBrand>
					
					<NavbarToggler onClick={ toggleNavbar } className="" aria-controls="basic-navbar-nav" />
					
					<Collapse isOpen={ !collapsed } navbar>
						<Nav navbar className="mr-auto">
							{ navItems.map( item => (
								<NavItem>
									<NavLink
										onClick={ toggleNavbar }
										tag={Link}
										to={ item.to }>
										
										{ item.name }
									</NavLink>
								</NavItem>
							))}
						</Nav>
					</Collapse>
				</Navbar>
				
				<React.Suspense fallback={ <div/> }>
					<Routes>
						{ routeElements }
					</Routes>
				</React.Suspense>
			</BrowserRouter>
		</React.Fragment>
	);
}

export default App
