// React Basic and Bootstrap
import React from 'react';
import { Button, Col, Row } from 'reactstrap';

//import images
import leapDayImage from '../../images/leap_day.png';
import { routePaths } from 'routes'
import { Link } from 'react-router-dom'

const About = ({ withButton = false }) => {
    
    let buttonView = null
    if( withButton ) {
        buttonView = (
            <Row className="align-items-center">
                <Col lg={12} className="align-items-center text-center">
                    <Link to={ routePaths.ABOUT }>
                        <Button>Lees hier meer over</Button>
                    </Link>
                </Col>
            </Row>
        )
    }
    
    return (
        <React.Fragment>
            <section className="section">
                <div className="container">
                    <div className="rounded">
                        <Row className="align-items-center">
                            <Col sm={6}>
                                <img src={leapDayImage} className="rounded img-fluid" style={{ maxWidth: '100%', maxHeight: '100%' }} alt="" />
                            </Col>

                            <Col sm={6}>
                                <div className="section-title p-1">
                                    <h4 className="title mb-1">Wat is een schrikkeljaar</h4>
                                    <p className="text-muted para-desc mb-0 text-justify">
                                        Een schrikkeljaar is een kalenderjaar met 366 dagen in plaats van 365. Deze extra dag, een schrikkeldag, wordt ingevoerd om te voorkomen dat de kalenderjaren te veel gaan afwijken van het tropisch jaar.
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>


                    <div className="rounded">
                        <Row className="align-items-center">
                            <Col lg={12}>
                                <div className="section-title p-1">
                                    <h4 className="title mb-1 mt-4">Kleine uitleg</h4>
                                    <p className="text-muted mb-0 text-justify">
                                        Eens in de vier jaar bestaat het jaar uit 366 dagen in plaats van 365. In dat jaar komt na 28 februari niet 1 maart, maar 29 februari. Dat jaar noemen we ook wel een schrikkeljaar en 29 februari een schrikkeldag.
                                    </p>
                                    <p className="text-muted mb-0 mt-2 text-justify">
                                        Ons ‘Gregoriaanse jaar’ duurt <strong>365,2425 dagen</strong>. Dat is 26 seconden langer dan de kalender zou zijn als je alleen naar de stand van de zon en de maan kijkt. In 3333 jaar tijd zou dat in totaal 86.400 seconden teveel zijn en dat is precies één dag. Daarom is het schrikkeljaar ingevoerd. Elk jaartal dat deelbaar is door 4, duidt een schrikkeljaar van 366 dagen aan. Per 400 jaar zouden er drie dagen teveel zijn. Hier hebben ze ook iets slims op bedacht. Alleen de eeuwjaren die je kunt delen door 400 (zoals het jaar 2000) zijn schrikkeljaren en de andere eeuwjaren niet.
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        { buttonView }
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default About;
