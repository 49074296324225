// React Basic and Bootstrap
import React from 'react';
import FactComponent from 'components/Fact'

const Fact3 = () => {
    
    return (
        <FactComponent
            title={ '3 | Er bestaat ook een schrikkelseconde' }
            paragraphs={[
                'Als we het hebben over een dag, bedoelen we eigenlijk een zonnedag. Dat is de tijd tussen twee zonsopkomsten op de evenaar, oftewel 24 uur. De aarde draait niet alleen om haar eigen as, maar ook rond de zon. Hierdoor moet onze planeet iets meer dan één volledige draai maken om weer met dezelfde kant naar de zon gericht te staan. In 24 uur draait de aarde dus iets meer dan één keer om haar as. Met andere woorden, een volledige draai van de aarde duurt iets minder dan 24 uur.',
                'Daarom wordt er af en toe een schrikkelseconde toegevoegd aan de tijd. Dit corrigeert het verschil tussen de lengte van een gemiddelde zonnedag en de exacte 24 uur op de klok.'
            ]}
        />
    )
}

export default Fact3;
