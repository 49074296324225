// React Basic and Bootstrap
import React from 'react';
import { Col, Row } from 'reactstrap';

// Import Generic components
import About from './../_shared/About';

//CountDown
import { Helmet } from "react-helmet";
import History from 'pages/About/History'

const Index = () => {
	
	return (
	    <React.Fragment>
		    <Helmet>
			    <title>{ 'Wat is een schrikkeljaar' }</title>
			    <meta name="description" content={`Wat is een schrikkeljaar eigenlijk? Hoe komen we aan een schrikkeljaar? Wat een vragen.. en hier is het antwoord!`} />
		    </Helmet>
		
		    <section className="bg-page">
			    <div className="bg-overlay bg-overlay-gradient"/>
			    <div className="home-center">
				    <div className="home-desc-center">
					    <div className="container">
						    <Row className="justify-content-center">
							    <Col lg={10} className="text-center">
								    <div className="title-heading">
									    <h1 className="display-4 text-white font-weight-bold mb-3">Wat is een schrikkeljaar</h1>
								    </div>
							    </Col>
						    </Row>
					    </div>
				    </div>
			    </div>
		    </section>
		 
			{/* About */}
			<About/>
			
			<History />
		</React.Fragment>
    );
}

export default Index;
