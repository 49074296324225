// React Basic and Bootstrap
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import { routePaths } from 'routes'
import Fact1 from 'pages/Facts/Items/Fact-1'
import { Link } from 'react-router-dom'

const Facts = () => {

    return (
        <React.Fragment>
            <section className="section">
                <div className="container">
                    <div className="rounded">
                        <Row className="align-items-center">
                            <Col className="">
                                <div className="section-title pb-0 pl-1 pr-1" style={{ paddingBottom: 0 }}>
                                    <h4 className="title">Feitjes</h4>
                                </div>
                            </Col>
                        </Row>

                        <Fact1 />

                        <Row className="align-items-center">
                            <Col lg={12} className="align-items-center text-center">
                                <Link to={ routePaths.FACTS }>
                                    <Button>Lees meer feitjes!</Button>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default Facts;
