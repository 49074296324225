const Leap = {
	
	isLeapYear: ( year ) => {
		return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
	},
	
	all: () => {
		let leapYears = [];
		for ( let year = 1501; year < 5000; year++ ) {
			if( Leap.isLeapYear( year ) ) {
				leapYears.push( year );
			}
		}
		return leapYears;
	}
}

export default Leap
