import React from 'react';

// Import all components
import Main from 'pages/Main/index';
import About from 'pages/About/index';
import Facts from 'pages/Facts/index';
import Overview from 'pages/Overview/index';

export const routePaths = {
    ABOUT: '/wat-is-een-schrikkeljaar',
    OVERVIEW: '/overzicht',
    FACTS: '/feitjes',
}

const routes = [
    { path: '/', component: Main, exact: true },

    // public Routes
    { path: routePaths.ABOUT, component: About, exact: true },
    { path: routePaths.FACTS, component: Facts, exact: true },
    { path: routePaths.OVERVIEW, component: Overview, exact: true },
    
    { path: '/:year', component: Main, exact: false },
];

export default routes;
