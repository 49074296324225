// React Basic and Bootstrap
import React from 'react';
import { Button, Col, Form, Input, InputGroup, Row } from 'reactstrap';
import { Link, useNavigate } from "react-router-dom";

const About = ({ year }) => {
    
    const navigate = useNavigate();
    
    const onSubmit = ( e ) => {
        const formData = new FormData( e.target );
        e.preventDefault();
        
        const values = {};
        for ( let [key, value] of formData.entries()) {
            values[ key ] = value;
        }
    
        navigate(`/${ values['year'] }`)
    };
    
    const randint = (min, max) => {
        return Math.round((Math.random() * Math.abs(max - min)) + min);
    };

    const yearMinPlus = 6;
    const yearList = [];
    for( let y = ( year - yearMinPlus ); y < ( year + yearMinPlus); y++ ) {
        yearList.push( y );
    }
    
    return (
        <React.Fragment>
            <section className="section border-top bg-light " id={"search"}>
                <div className="container">
                    <Row className="align-items-center">
                        <Col lg={12}>
                            <div className="section-title p-1 text-center">
                                <h4 className="title mb-4 text-center">Welk jaar is nog meer een schrikkeljaar</h4>
                                <p className="text-muted para-desc mb-0 text-center" style={{ margin: '0 auto'}}>
                                    Vul het jaartal in onderstaand veld in.
                                </p>
                                
                                <Row>
                                    <Col lg={6} sm={12} style={{ margin: '0 auto'}}>
                                        <Form
                                            onSubmit={ onSubmit }>
                                            <InputGroup>
                                                <Input
                                                    name={'year'}
                                                    type={'number'}
                                                    defaultValue={ randint( 1000, 3000 ) }
                                                    style={{ height: '42px', lineHeight: '42px' }}
                                                />
                                                <Button color="secondary">Bekijken</Button>
                                            </InputGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row className="align-items-center">
                        <Col lg={12}>
                            <div className="container-fluid">
                                <div className="align-items-center text-center">
                                    <p className="text-muted para-desc mb-0 text-center" style={{ margin: '0 auto'}}>
                                        Of.. kies één van de onderstaande jaren
                                    </p>
                                    
                                    { yearList.map( year => (
                                        <Link to={`/${year}`} style={{ color: '#fff' }}>
                                            <Button style={{ margin: '5px' }}>
                                                { year }
                                            </Button>
                                        </Link>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </React.Fragment>
    );
}

export default About;
