// React Basic and Bootstrap
import React from 'react';
import { Col, Row } from 'reactstrap';

const History = () => {

    return (
        <section className="">
            <div className="container">
                <div className="rounded">
                    <Row className="align-items-center">
                        <Col lg={12}>
                            <div className="section-title p-1">
                                <h4 className="title mb-4">Geschiedenis</h4>
                                <p className="text-muted mb-4">De schrikkeldag werd in 46 voor Christus ingevoerd door Julius Caesar. Hij hervormde de Romeinse kalender en bepaalde dat er schrikkeldagen moesten worden toegevoegd. De oud-Romeinse kalender had verschillende maanden met slechts 29 dagen, waardoor een jaar slechts 355 dagen lang was. Om de maanden in lijn te houden met de seizoenen, werd om de twee jaar een schrikkelmaand (mensis intercalaris) toegevoegd.</p>

                                <p className="text-muted mb-4">Hierdoor ontstond een jaar van 365 dagen, en de maanden kwamen beter overeen met de seizoenen. Schrikkelmaanden waren niet meer nodig, maar een schrikkeldag wel. De aarde draait namelijk in 365 dagen en ongeveer 6 uur om de zon. Elk kalenderjaar was dus 6 uur korter dan een zonnejaar. Caesar besloot daarom dat er eens in de vier jaar (4×6=24 uur) een schrikkeldag moest worden toegevoegd om dit verschil te corrigeren.</p>

                                <p className="text-muted mb-4">Caesar's berekening was echter niet helemaal precies, waardoor er in de zestiende eeuw een verschil van tien dagen was ontstaan.</p>

                                <p className="text-muted mb-0">Paus Gregorius XIII wilde dit in 1582 herstellen. De Gregoriaanse kalender werd ingevoerd, en de achterstand werd in één keer ingehaald door van 4 oktober 1582 direct naar 15 oktober 1582 te gaan. Om te voorkomen dat er weer zo’n groot verschil zou ontstaan, bepaalde de paus dat eeuwjaren geen schrikkeljaar zouden zijn, behalve als ze deelbaar waren door 400. Daarom was 1900 geen schrikkeljaar, maar 2000 wel.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </section>
    );
}

export default History;
