import React from 'react';
import { Col, Row } from 'reactstrap';
import { Helmet } from "react-helmet";
import Leap from 'library/Leap'

const Index = () => {
	
	const leapYears = Leap.all();
	
	return (
	    <React.Fragment>
		    <Helmet>
			    <title>{ 'Schrikkeljaren overzicht' }</title>
			    <meta name="description" content={`Een overzicht van alle schrikkeljaren.. dat is pas handig!`} />
		    </Helmet>
		
		    <section className="bg-page">
			    <div className="bg-overlay bg-overlay-gradient"/>
			    <div className="home-center">
				    <div className="home-desc-center">
					    <div className="container">
						    <Row className="justify-content-center">
							    <Col lg={10} className="text-center">
								    <div className="title-heading">
									    <h1 className="display-4 text-white font-weight-bold mb-3">Alle schrikkeljaren</h1>
								    </div>
							    </Col>
						    </Row>
					    </div>
				    </div>
			    </div>
		    </section>
		
		    <section className="section">
			    <div className="container">
				    <p>Een schrikkeljaar is een jaar dat een extra dag heeft, de schrikkeldag, om het kalenderjaar te synchroniseren met het astronomische jaar. Volgens de gregoriaanse kalender, die sinds de 16e eeuw gangbaar is, zijn schrikkeljaren alle jaren die deelbaar zijn door 4, behalve eeuwjaren (jaren die eindigen op 00) die niet deelbaar zijn door 400. Hier is een overzicht van schrikkeljaren sinds het begin van de 21e eeuw:</p>
				    
				    <div className="w-100">
					    <ul className="list-group list-group-horizontal-md w-100">
						    <li className="list-group-item flex-grow-1">
							    <strong>Jaar</strong>
						    </li>
						    <li className="list-group-item">
							    <strong>Berekening</strong>
							</li>
					    </ul>
					    
					    { leapYears.map( ( year, index ) => {
						    
						    // Show which calculation is used for the leap year
						    let calculation = '';
						    if ( year % 4 === 0 && year % 100 !== 0 ) {
							    calculation = 'Deelbaar door 4, maar niet door 100';
						    } else if ( year % 400 === 0 ) {
							    calculation = 'Deelbaar door 400';
						    }
						    
						    return (
								<ul className="list-group list-group-horizontal-md w-100">
								    <li className="list-group-item flex-grow-1">
									    { year }
								    </li>
								    <li className="list-group-item">{ calculation }</li>
							    </ul>
						    )
					    } ) }
				    </div>
			    </div>
		    </section>
	    </React.Fragment> );
}

export default Index;
