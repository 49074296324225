// React Basic and Bootstrap
import React, { Component, useEffect, useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import About from './../_shared/About';
import Facts from './Facts';
import Search from './Search';
import Countdown from 'react-countdown-now';
import Leap from "library/Leap";
import leapYearAnimation from '../../images/animation.gif';
import calendarImage from '../../images/undraw_calendar_dutt.svg';
import { Helmet } from "react-helmet";
import { Link, useLocation, useParams } from 'react-router-dom'
import { routePaths } from 'routes'
import Fact1 from 'pages/Facts/Items/Fact-1'

// Random component
const Completionist = () => <span></span>;

// Renderer callback with condition
const renderer = ( {days, hours, minutes, seconds, completed} ) => {
	if ( completed ) {
		// Render a complete state
		return <Completionist/>;
	} else {
		
		const dayText = days > 1 ? 'dagen' : 'dag'
		const hourText = hours > 1 ? 'uren' : 'uur'
		const minuteText = minutes > 1 ? 'minuten' : 'minuut'
		const secondText = seconds > 1 ? 'seconden' : 'seconde'
		
		// Render a countdown
		return (
			<React.Fragment>
				<br />
				<br />
				<p className="para-desc mx-auto text-light">
					Het volgende schrikkeljaar is over:
				</p>
				
				<div className="count-down text-center">
					<span className="count-number">{days}</span>
					<span className="count-head position-relative d-block">
						{ dayText }
					</span>
				</div>
				<div className="count-down text-center">
					<span className="count-number">{hours}</span>
					<span className="count-head position-relative d-block">
						{ hourText }
					</span>
				</div>
				<div className="count-down text-center">
					<span className="count-number">{minutes}</span>
					<span className="count-head position-relative d-block">
						{ minuteText }
					</span>
				</div>
				<div className="count-down text-center">
					<span className="count-number">{seconds}</span>
					<span className="count-head position-relative d-block">
						{ secondText }
					</span>
				</div>
			</React.Fragment>
		)
	}
};

const Index = () => {
	
	const currentYear = new Date().getFullYear()
	
	const location = useLocation()
	let params = useParams();
	
	const [ year, setYear ] = useState()
	
	const getYear = () => {
		let year = parseInt( params?.year );
		
		if( typeof year === 'undefined' || isNaN( year ) ) {
			year = new Date().getFullYear();
		}
		
		return year;
	}
	
	useEffect( () => {
		let _year = getYear()
		if( _year <= 0 || _year >= ( currentYear + 10000 ) ) {
			_year = currentYear
		}
		
		if( _year !== year ) {
			setYear( _year )
		}
	}, [ params?.year ] )
    
    const isLeapYear = Leap.isLeapYear( year );

    let nextLeapYear = year;
    for ( let y = (year + 1); y <= ( year + 50 ); y++ ) {
		if( Leap.isLeapYear( y ) ) {
			nextLeapYear = y;
			break;
		}
    }
    
    const nextLeapYearDate = new Date( nextLeapYear, 0, 1 );
    
    const backgroundImage = isLeapYear ? leapYearAnimation : calendarImage;
    const sectionStyle = isLeapYear ? {
	    background: `url("${backgroundImage}")`,
	    backgroundRepeat: 'no-repeat',
	    backgroundSize: 'contain',
	    backgroundPosition: "center bottom"
    } : {
	    background: `url("${backgroundImage}")`,
	    backgroundRepeat: 'no-repeat',
	    backgroundSize: '45%',
	    backgroundPosition: "left bottom"
    };
	
	let countdownView = null;
	if( year == currentYear ) {
		countdownView = (
			<Row>
				<Col md={12} className="text-center">
					<div id="eventdown">
						<Countdown date={nextLeapYearDate} renderer={renderer}/>
					</div>
				</Col>
			</Row>
		)
	}
 
	return ( <React.Fragment>
			<Helmet>
				<title>{ currentYear.toString() }</title>
			</Helmet>
			
			{/* Hero Start */ }
			<section
				className="bg-home"
				style={ sectionStyle }>
				<div className="bg-overlay bg-overlay-gradient" />
				<div className="home-center">
					<div className="home-desc-center">
						<div className="container">
							<Row className="justify-content-center">
								<Col
									lg={ 10 }
									className="text-center">
									<div className="title-heading">
										<h4 className="mb-3">
											<span
												style={ {
													background: '#fff',
													padding: '8px 16px',
												} }>
												
												{ year }
											</span>
										</h4>
										
										<h1 className="display-4 text-white font-weight-bold mb-3">{ isLeapYear ? 'is een schrikkeljaar' : 'is geen schrikkeljaar' }</h1>
										
										{ countdownView }
									</div>
								</Col>
							</Row>
							
							<Row className="justify-content-center">
								<Col
									lg={ 10 }
									className="text-center">
									<Link to={ routePaths.OVERVIEW }>
										<Button>Bekijk hier alle schrikkeljaren</Button>
									</Link>
								</Col>
							</Row>
						</div>
						
						<div className="container-fluid">
							<div className="home-shape-bottom">
								<div className="text-center bg-white p-4">
									<h5 className="text-dark mb-0">Volgend schrikkeljaar: { nextLeapYear }</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			
			{/* Search */ }
			<Search
				year={ year }
			/>
			
			{/* About */ }
			<About
				withButton={ true }
			/>
			
			{/* News */ }
			<Facts />
			
			{/* Hospitality */}
			<section className="section">
				<div className="container">
					<div className="rounded">
						<Row className="align-items-center">
							<Col className="">
								<div
									className="section-title pb-0 pl-1 pr-1"
									style={ { paddingBottom: 0 } }>
									<h4 className="title">Hospitality Manager: de tool voor evenementenbeheer</h4>
									<p>Klaar om uw gastregistraties naar een hoger niveau te tillen? Ontdek de kracht van onze Hospitality Manager - dé ultieme event registratie software.</p>
									<p>Profiteer van onze 18 jaar ervaring en geniet van alles wat je nodig hebt, zonder verrassingen - één vaste prijs per maand.</p>
								</div>
							</Col>
						</Row>
						
						<Row className="align-items-center">
							<Col
								lg={ 12 }
								className="align-items-center text-center">
								<Link to={ 'https://hospitalitymanager.eu' }>
									<Button>Bekijk nu!</Button>
								</Link>
							</Col>
						</Row>
					</div>
				</div>
			</section>
		</React.Fragment> );
}

export default Index;
