import React from 'react';
import { Col, Row } from 'reactstrap';
import { Helmet } from "react-helmet";
import Fact1 from 'pages/Facts/Items/Fact-1'
import Fact2 from 'pages/Facts/Items/Fact-2'
import Fact3 from 'pages/Facts/Items/Fact-3'
import Fact4 from 'pages/Facts/Items/Fact-4'
import Fact5 from 'pages/Facts/Items/Fact-5'

const Index = () => {
	
	return (
	    <React.Fragment>
		    <Helmet>
			    <title>{ 'Weetjes' }</title>
			    <meta name="description" content={`Weetjes zijn altijd leuk. En weetjes over een schrikkeljaar al helemaal! Wist je bijvoorbeeld dat ...`} />
		    </Helmet>
		
		    <section className="bg-page">
			    <div className="bg-overlay bg-overlay-gradient"/>
			    <div className="home-center">
				    <div className="home-desc-center">
					    <div className="container">
						    <Row className="justify-content-center">
							    <Col lg={10} className="text-center">
								    <div className="title-heading">
									    <h1 className="display-4 text-white font-weight-bold mb-3">Feitjes</h1>
								    </div>
							    </Col>
						    </Row>
					    </div>
				    </div>
			    </div>
		    </section>
		
		    <section className="section">
			    <div className="container">
					<Fact1 />
					
					<Fact2 />
					
					<Fact3 />
					
					<Fact4 />
					
					<Fact5 />
			    </div>
		    </section>
		</React.Fragment>
    );
}

export default Index;
