// React Basic and Bootstrap
import React from 'react';
import FactComponent from 'components/Fact'

const Fact4 = () => {

    return (
        <FactComponent
            title={ '4 | Het jaar 2020 kent ook een schrikkelweek' }
            paragraphs={[
                'Blader eens vooruit in je agenda naar de laatste week van het jaar. Je ziet dan niet week 52, maar week 53. Naast schrikkelsecondes en schrikkeldagen (en dus schrikkeljaren) zijn er ook schrikkelweken. Dit zit zo: een kalenderjaar heeft 365 dagen, wat één dag meer is dan 52 weken (364 dagen). Een schrikkeljaar heeft zelfs twee dagen meer dan 52 weken. Na zes jaar heb je daardoor een extra week. Het jaar 2020 had dus, naast een schrikkeldag, ook een extra week.',
            ]}
        />
    )
}

export default Fact4;
