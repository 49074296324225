// React Basic and Bootstrap
import React from 'react';
import { Col, Row } from 'reactstrap';

const FactComponent = ({ title = null, paragraphs = [] } ) => {
	
	return (
		<React.Fragment>
			<Row className="align-items-center">
				<Col lg={12}>
					<div className="section-title p-1">
						<h4 className="title mb-1">{ title }</h4>
						
						{ paragraphs.map( p => (
							<p className="text-muted mb-4 text-justify">
								{ p }
							</p>
						))}
					</div>
				</Col>
			</Row>
		</React.Fragment>
	);
}

export default FactComponent;
