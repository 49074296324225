import React from 'react';

// Layout Components
import Footer from './Footer';

const Layout = ({ children }) => {
	
	return (
		<React.Fragment>
			{ children }
			
			<Footer />
		</React.Fragment>
	);
}

export default Layout;
