// React Basic and Bootstrap
import React from 'react';
import FactComponent from 'components/Fact'

const Fact2 = () => {
    
    return (
        <FactComponent
            title={ '2 | Schrikkeljaar komt van ‘scricken’' }
            paragraphs={[
                'Het woord \'schrikkel\' komt van het Middelnederlandse woord ‘scricken’. Middelnederlands was de taal na het Oudnederlands en voor het moderne Nederlands. \'Scricken\' betekende ‘met grote passen lopen’ of ‘springen’. Dit past goed bij een schrikkeljaar, want met een schrikkeldag maken we een sprong om het kalenderjaar gelijk te laten lopen met het astronomische jaar.'
            ]}
        />
    )
}

export default Fact2;
