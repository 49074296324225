// React Basic and Bootstrap
import React from 'react';
import FactComponent from 'components/Fact'

const Fact1 = () => {

    return (
        <FactComponent
            title={ '1 | De juliaanse kalender berekende schrikkeljaren verkeerd' }
            paragraphs={[
                'Vanaf de zestiende eeuw gebruiken bijna alle landen de gregoriaanse kalender, genoemd naar paus Gregorius XIII. Daarvoor gebruikten ze de juliaanse kalender, genoemd naar Julius Caesar. De juliaanse kalender berekende schrikkeljaren op een niet-accurate manier: elk jaar dat deelbaar was door 4 was een schrikkeljaar. Dit klopte niet met de echte tijd die de aarde nodig heeft om rond de zon te draaien.',
                'De gregoriaanse kalender heeft een nauwkeurigere manier om schrikkeljaren te berekenen. Een jaar is een schrikkeljaar als het deelbaar is door 4, behalve als het ook deelbaar is door 100, tenzij het ook deelbaar is door 400. Bijvoorbeeld, 1600 en 2000 waren schrikkeljaren, maar 1700 en 1900 niet. Hierdoor duurt een jaar in de gregoriaanse kalender gemiddeld 365,2425 dagen, wat iets langer is dan een astronomisch jaar. Deze afwijking is echter heel klein: slechts één dag per 3200 jaar.'
            ]}
        />
    );
}

export default Fact1;
